<template>
  <sgv-table
    :items="devices"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">
    <template slot-scope="{item, hidden}">
      <tr>
        <td v-if="hidden.code">
          <router-link
            class="text-decoration-none"
            :class="{'text-danger': !item.isActive}"
            :to="toDetail(item.id)">
            {{item.code}}
          </router-link>
        </td>
        <td v-if="hidden.name">{{ item.name }}</td>
      </tr>
    </template>

    <router-link
      slot="action"
      v-if="$auth.hasRole(`kioskDevice:${kioskDeviceType}:add`)"
      :to="toDetail(0)">
      <button
        type="button"
        class="btn btn-link text-success">
        เพิ่ม
      </button>
    </router-link>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_DEVICE } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    kioskDeviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `KioskDevice${this.$form.capitalize(this.kioskDeviceType)}Detail`,
      rKey: `KioskDevice${this.$form.capitalize(this.kioskDeviceType)}ListTable`,
      rFields: ['filter', 'options'],
      headers: [
        {text: 'รหัส', value: 'code', filter: true},
        {text: 'ชื่อ', value: 'name', filter: true},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name'],
        column: null,
        search: null,
        toolbar: null,
      },
      roles: [],
    }
  },
  apollo: {
    devices: {
      query () {
        return LIST_DEVICE(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          kioskDeviceType: this.kioskDeviceType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      const filter = {...v, params: v.params || {}}
      return filter
    },
    toDetail (id) {
      return {
        name: this.detailView,
        params: {kioskDeviceId: id}
      }
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
}
</script>

<style lang="css" scoped>
</style>
